.about-section {
  padding: 50px 20px; 
  text-align: center; /* Adjust based on preference */
}

.profile-pic {
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.about-content {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin-top: 20px;
  gap: 20px; /* Optional: Adds space between the image and text */
}

/* Additional styling for the text next to the profile picture to ensure it aligns properly */
.about-text {
  flex-grow: 1;
  height: 200%; /* Make the text container the same height as the profile picture */
  padding: 0 20px; /* Adds padding around the text for better spacing */
}
