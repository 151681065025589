.projects-section {
    text-align: center;
    padding: 50px 0;
  }
  
  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adjust based on your preference */
  }
  
  .project-card {
    position: relative;
    width: 250px; /* Adjust based on your layout needs */
    overflow: hidden; /* Ensure overflow content is not visible */
    border-radius: 8px; /* Optional: adds rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for depth */
    transition: transform 0.3s ease;
  }
  
  .project-card img {
    width: 100%;
    height: auto;
    transition: opacity 0.3s ease;
  }
  
  .project-card:hover img {
    opacity: 0.6;
  }
  
  .project-card h3,
  .project-card p,
  .project-card a {
    margin: 12px;
  }
  
  .project-card a {
    display: inline-block;
    text-decoration: none;
    background-color: #007bff; /* Primary button color */
    color: white;
    padding: 10px 15px;
    border-radius: 5px; /* Rounded edges for the button */
    transition: background-color 0.3s ease;
  }
  
  .project-card a:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay */
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
  }
  
  .project-card:hover .overlay {
    opacity: 1;
  }
  
  /* Responsiveness: Adjust cards per row based on screen width */
  @media (max-width: 768px) {
    .projects-grid {
      justify-content: center;
    }
  
    .project-card {
      width: 90%; /* Adjust based on your preference for mobile */
    }
  }
  